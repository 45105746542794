// Override Bootstrap's Sass default variables

// Toggle global options
$enable-shadows: true;

// Customize some defaults
$primary: #00abc9;
$success: #5cb85c;
$border-radius: .4rem;
$min-contrast-ratio: 2.5;
$font-family-base: "Open Sans", system-ui, -apple-system, sans-serif;

// Import Bootstrap's CSS
@import 'common-imports';

// Custom styles
@import 'shared-custom-styles';
