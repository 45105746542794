a:not([class]),
a.badge,
a.link-primary,
.btn-link {
  text-decoration: none;
}

a:not([class]):hover,
a.link-primary:hover,
.btn-link:hover {
  text-decoration: underline;
}

.page-header {
  padding-bottom: 1rem;
}

.card.border-primary,
.card-header.border-primary {
  border-color: rgba(var(--bs-primary-rgb), 0.5) !important;
}

.card.border-success,
.card-header.border-success {
  border-color: rgba(var(--bs-success-rgb), 0.5) !important;
}

.card.border-warning,
.card-header.border-warning {
  border-color: rgba(var(--bs-warning-rgb), 0.5) !important;
}

.card.border-info,
.card-header.border-info {
  border-color: rgba(var(--bs-info-rgb), 0.5) !important;
}

.card.border-danger,
.card-header.border-danger {
  border-color: rgba(var(--bs-danger-rgb), 0.5) !important;
}

.card-header.bg-primary,
.card-header.bg-success,
.card-header.bg-warning,
.card-header.bg-info,
.card-header.bg-danger {
  --bs-bg-opacity: 0.5;
}

.badge-keyword {
  font-size: 85%;
  margin-bottom: 0.3rem;
  line-height: 1.4rem;
}

.btn-outline-secondary {
  --#{$prefix}btn-color: var(--bs-gray-900);
  --#{$prefix}btn-border-color: var(--bs-gray-400);
  --#{$prefix}btn-hover-color: var(--bs-gray-900);
  --#{$prefix}btn-hover-bg: var(--bs-gray-200);
  --#{$prefix}btn-hover-border-color: var(--bs-gray-400);
  --#{$prefix}btn-focus-shadow-rgb: var(--bs-gray-400);
  --#{$prefix}btn-active-color: var(--bs-gray-900);
  --#{$prefix}btn-active-bg: var(--bs-gray-300);
  --#{$prefix}btn-active-border-color: var(--bs-gray-400);
  --#{$prefix}btn-disabled-color: var(--bs-gray-900);
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: var(--bs-gray-400);
}
